// @flow
import type { JWTRole, GlobalRole, Claims } from "overclick";

const SECONDS_IN_ONE_DAY = 24 * 60 * 60;

function getToken(): string {
  const name = "id_token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = ca.length - 1; i >= 0; --i) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

function setToken(token: string, maxAge?: number = SECONDS_IN_ONE_DAY): void {
  document.cookie = `id_token=${token}; max-age=${maxAge}; path=/; domain=${window.location.hostname}`;
}

function getClaims(): Claims {
  const token = getToken();

  const base64Url = token.split(".")[1] || "";

  const base64 = decodeURIComponent(
    atob(base64Url).replace(/(.)/g, (_, c) => {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
  );

  return JSON.parse(base64 || "{}");
}

function isAuthenticated(): boolean {
  return !!getToken();
}

function logout(): void {
  setToken("", -1);
}

function globalToJWTRoleConvertor(role: GlobalRole): JWTRole {
  switch (role) {
    case "Affiliate":
      return "aff";
    case "AffiliateManager":
      return "afm";
    case "HeadAffManagers":
      return "ham";
    case "AdvertiserManager":
      return "inm";
    case "HeadAdvManagers":
      return "him";
    case "Administrator":
      return "adm";
    default:
      return (role: empty);
  }
}

export default {
  getToken,
  setToken,
  getClaims,
  isAuthenticated,
  logout,
  globalToJWTRoleConvertor,
};
