// @flow
import Rollbar from "rollbar";

const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV === "production",
  });

  const logErrorInfo = (info: mixed) => {
    RollbarObj.info(info);
  };

  const logErrorInRollbar = (error: mixed) => {
    throw new Error(error);
  };

  return { logErrorInfo, logErrorInRollbar };
})();

export default RollbarErrorTracking;
