// @flow
import * as React from "react";

import Links from "./Links";

import type { Claims } from "overclick";

type Props = {
  claims: Claims,
};

function Navigation({ claims }: Props): React.Node {
  switch (claims.rol) {
    case "adm":
    case "him":
    case "ham":
    case "inm":
    case "afm":
      return <Links.Manager />;
    case "aff":
      return <Links.Affiliate />;
    default:
      return null;
  }
}

export default Navigation;
