// @flow
import * as React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "material-ui/styles";

import App from "./App";
import ErrorBoundary from "./ErrorBoundary";

import store from "./state/store";

import * as serviceWorker from "./serviceWorker";

import "./index.scss";

const root = document.getElementById("root");

if (root !== null) {
  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter basename="/">
        <MuiThemeProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </MuiThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
