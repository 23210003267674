// @flow
import { connect } from "react-redux";

import AutoComplete from "views/UI/material-ui/AutoComplete";

import type { State as AffiliateState } from "state/reducers/affiliates";

type DataSourceObject = { value: number, text: string };

export type Request = DataSourceObject;

type DataSource = DataSourceObject[];

type OwnProps = {};

type Props = {
  ...OwnProps,
  dataSource: DataSource,
};

type State = {
  affiliates: AffiliateState,
};

const mapStateToProps = ({ affiliates }: State) => {
  const dataSource: DataSource = [];

  for (const [key, value] of affiliates.items) {
    dataSource.push({ value: key, text: `(${key}) ${value}` });
  }

  return { dataSource };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, {})(AutoComplete);
