import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import offerReducer from "./offers";
import affiliateReducer from "./affiliates";
import advertiserReducer from "./advertisers";
import configurationReducer from "./configuration";

import managerReducer from "./managers";
import affiliateManagerReducer from "./affiliateManagers";
import advertiserManagerReducer from "./advertiserManagers";

import affiliateBalanceReducer from "./affiliateBalance";
import pushNotificationReducer from "./pushNotifications";

export default combineReducers({
  offers: offerReducer,
  affiliates: affiliateReducer,
  advertisers: advertiserReducer,
  configuration: configurationReducer,

  affiliateBalance: affiliateBalanceReducer,
  pushNotifications: pushNotificationReducer,

  managers: managerReducer,
  affiliateManagers: affiliateManagerReducer,
  advertiserManagers: advertiserManagerReducer,

  form: formReducer,
});
