// @flow
import {
  FETCH_AFFILIATES_BEGIN,
  FETCH_AFFILIATES_SUCCESS,
  FETCH_AFFILIATES_FAILURE,
} from "../actions/affiliates";

import type { Action } from "../actions/affiliates";

export type State = {
  items: Map<number, string>,
  loading: boolean,
  error: Object,
};

const INITIAL_STATE: State = {
  items: new Map(),
  loading: false,
  error: null,
};

export default function reducer(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case FETCH_AFFILIATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_AFFILIATES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.affiliates.reduce((acc, { _id, company }) => {
          acc.set(_id, company);

          return acc;
        }, new Map()),
      };
    case FETCH_AFFILIATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
