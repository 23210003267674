// @flow
import type { OfferSimple } from "offer";

export const FETCH_OFFERS_BEGIN = "FETCH_OFFERS_BEGIN";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAILURE = "FETCH_OFFERS_FAILURE";

type FetchOffersBegin = {
  type: typeof FETCH_OFFERS_BEGIN,
};

type FetchOffersSuccess = {
  type: typeof FETCH_OFFERS_SUCCESS,
  payload: { offers: OfferSimple[] },
};

type FetchOffersFailure = {
  type: typeof FETCH_OFFERS_FAILURE,
  payload: { error: Object },
};

export type Action = FetchOffersBegin | FetchOffersSuccess | FetchOffersFailure;

export function fetchOffersBegin() {
  return {
    type: FETCH_OFFERS_BEGIN,
  };
}

export function fetchOffersSuccess(offers: OfferSimple[]) {
  return {
    type: FETCH_OFFERS_SUCCESS,
    payload: { offers },
  };
}

export function fetchOffersFailure(error: Object) {
  return {
    type: FETCH_OFFERS_FAILURE,
    payload: { error },
  };
}

export function fetchOffers() {
  return async (dispatch: (Action) => void) => {
    const API = (await import("services/api")).default;

    dispatch(fetchOffersBegin());

    API.get("offers/simple")
      .then(({ data }) => dispatch(fetchOffersSuccess(data)))
      .catch((err) => dispatch(fetchOffersFailure(err)));
  };
}
