import React from "react";

import MUIAutoComplete from "material-ui/AutoComplete";

import { STYLE, HINT_STYLE, TEXT_FIELD_STYLE, MENU_ITEM_STYLE, UNDERLINE_STYLE } from "./styles";

function AutoComplete(props) {
  const [searchText, setSearchText] = React.useState(() => {
    return props.searchText ? props.searchText : undefined;
  });

  const handleFilter = (searchText, key) => {
    return key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  };

  const handleMenuKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.target.dispatchEvent(new Event("click", { bubbles: true }));
    }
  };

  const handleUpdateInput = (value) => {
    if (value === "") {
      props.onNewRequest({ value: null, text: "" });
    }

    setSearchText(value);
  };

  const menuProps = {
    maxHeight: 200,
    menuItemStyle: MENU_ITEM_STYLE,
    onKeyUp: handleMenuKeyUp,
  };

  const textFieldStyle = { ...TEXT_FIELD_STYLE, ...props.textFieldStyle };
  const underlineStyle = { ...UNDERLINE_STYLE, ...props.underlineStyle };

  return (
    <MUIAutoComplete
      filter={handleFilter}
      {...props}
      style={STYLE}
      hintStyle={HINT_STYLE}
      textFieldStyle={textFieldStyle}
      underlineStyle={underlineStyle}
      menuProps={menuProps}
      searchText={searchText}
      onUpdateInput={handleUpdateInput}
    />
  );
}

AutoComplete.defaultProps = {
  dataSource: [],
};

export default AutoComplete;
