// @flow
import * as React from "react";

import { NavLink } from "react-router-dom";

type Event = SyntheticMouseEvent<HTMLLinkElement> | SyntheticKeyboardEvent<HTMLLinkElement>;

type Props = {
  to: string,
  children: React.Node,
  onClick?: (Event) => void,
};

function Link(props: Props): React.Node {
  const handleClick = (event: Event) => {
    if (window.location.pathname === props.to && !event.ctrlKey && !event.metaKey) {
      window.location.href = props.to;
    }

    props.onClick && props.onClick(event);
  };

  return <NavLink {...props} onClick={handleClick} />;
}

export default Link;
