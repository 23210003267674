// @flow
import * as React from "react";

import { Link } from "react-router-dom";

import JumpTo from "./JumpTo";
import AffiliateBalance from "./AffiliateBalance";

import type { Claims } from "overclick";

type Props = { ...Claims };

function ProfileLink({ id, name, rol }: Props): React.Node {
  switch (rol) {
    case "adm":
    case "him":
    case "ham":
    case "inm":
    case "afm":
      return (
        <React.Fragment>
          <JumpTo />
          <Link to={`/control-panel/users/${id}`}>{name}</Link>
        </React.Fragment>
      );
    case "aff":
      return (
        <React.Fragment>
          <AffiliateBalance />
          <Link to={`/affiliates/${id}`} className="ml-3">
            {`(${id}) ${name}`}
          </Link>
        </React.Fragment>
      );
    default:
      return null;
  }
}

export default ProfileLink;
